<template>
  <h-table
    ref="page"
    :options="options"
    :columns="tableColumns"
    :data="tableData"
    :loading="tableLoading"
  >
    <div slot="form-footer" style="text-align: center">
      <Button @click="auditSubmit(true)" type="primary">审核通过</Button>
      <Button @click="auditSubmit(false)" type="warning">驳回申请</Button>
    </div>
  </h-table>
</template>
<script>
export default {
  data() {
    return {
      tableColumns: [
        {
          title: "登陆账号",
          key: "account",
        },
        {
          title: "申请人/企业",
          key: "name",
        },
        {
          title: "联系电话",
          key: "phone",
        },
        {
          title: "地址",
          key: "address",
        },
        {
          title: "操作",
          render: (h, { row }) => {
            return (
              <span>
                <a on-click={() => this.audit(row)}>审核</a>
              </span>
            );
          },
        },
      ],
      tableData: [],
      tableLoading: false,
      model: {
        checkUserId: "",
        account: "",
        name: "",
        phone: "",
      },
      //   page: {
      //     pageNo: 1,
      //     pageSize: 12,
      //     total: 0,
      //   },
    };
  },
  computed: {
    options() {
      return {
        // page: { ...this.page, onChange: this.pageChange },
        form: {
          model: this.model,
          rules: {
            account: {
              name: "登陆账号",
              readonly: true,
              type: "i",
            },
            name: {
              name: "申请人/企业",
              readonly: true,
              type: "i",
            },
            phone: {
              name: "联系方式",
              readonly: true,
              type: "i",
            },
          },
        },
        check: {
          text: "审核申请",
        },
      };
    },
  },
  methods: {
    //   查询数据
    getList() {
      this.tableLoading = true;
      this.$post(this.$api.regist.LIST, {
        // pageNo: this.page.pageNo,
        // pageSize: this.page.pageSize,
      })
        .then((res) => {
          this.tableData = res;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 分页
    // pageChange(pageNo) {
    //   this.page.pageNo = pageNo;
    //   this.getList();
    // },

    // 审核页面
    audit(row) {
      for (let key in this.model) {
        this.model[key] = row[key];
      }
      this.model.checkUserId = row.id;
      this.$refs.page.check();
    },
    auditSubmit(check) {
      this.$post(this.$api.regist.AUDIT, {
        checkUserId: this.model.checkUserId,
        check,
      }).then((res) => {
        this.$refs.page.closeModal();
        this.model.checkUserId = "";
        this.getList();
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>